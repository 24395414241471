const baseInputClass =
  'transition duration-100 block rounded-[8px] px-4 pb-3 pt-6 lg:pt-7 w-full text-[18px] lg:text-[16px] text-[#1C2024] appearance-none border-[1.5px] outline-none focus:ring-0  peer disabled:bg-[#FAF8FF] disabled:text-[#65636D]';

export const defaultInputClass =
  baseInputClass + ' ' + 'bg-white border-[#6A00FF18] focus:border-[#45007F]';

export const errorInputClass =
  baseInputClass +
  ' ' +
  'bg-palePeach border-red-700 focus:border-red-700 autofill:shadow-[inset_0_0_0px_1000px_#FBE9E2]';

export const labelClass =
  'cursor-text absolute text-[16px] lg:text-[16px] text-gray-500 duration-300 transform -translate-y-4 scale-75 left-[18px] lg:left-[16px] top-5 z-10 origin-[0] start-2.5 peer-focus:text-[#817F8B] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4';

export const inputWrapperClass = 'relative w-full';

export const errorMessageClass = 'w-full pt-2 pl-2';
