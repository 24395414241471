import { ReactNode } from 'react';

import openExternalLink from '@/utils/openExternalLink';

export const ExternalLinkButton = ({
  children,
  url,
}: {
  url: string;
  children: ReactNode;
}) => {
  const handleClick = () => openExternalLink(url);
  return (
    <button className='underline' onClick={handleClick}>
      {children}
    </button>
  );
};
