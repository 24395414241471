import Image from 'next/image';

import { ExternalLinkButton } from './ExternalLinkButton';

export const TermsAndConditions = () => (
  <div className='flex flex-col items-center gap-4 m-8'>
    <p className='font-light text-center text-[12px] text-[#211F26]'>
      {"By signing up, I agree to Sheer Health's "}
      <ExternalLinkButton url='https://www.sheerhealth.com/privacy-policy'>
        Privacy Policy
      </ExternalLinkButton>
      {' and '}
      <ExternalLinkButton url='https://www.sheerhealth.com/terms'>
        Terms of Service
      </ExternalLinkButton>
      .
    </p>
    <Image
      alt=''
      className='w-[100px] h-auto'
      height={100}
      src='/images/HIPAACompliantBadge.png'
      width={100}
    />
  </div>
);
