import getFromLocalStorage from './getFromLocalStorage';
import postMessageToApp from './postMessageToApp';

export default function openExternalLink(url: string, inAppPortal?: boolean) {
  const isInApp = getFromLocalStorage('attributionSource') === 'native';

  if (isInApp || inAppPortal) {
    postMessageToApp('open_in_browser', url);
  } else {
    window.open(url, '_blank');
  }
}
