'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { signInWithEmailLink } from '@/firebase/auth';
import useSegmentAnalytics from '@/hooks/useSegmentAnalytics';
import getPreservedQueryString from '@/utils/getPreservedQueryString';

import { Button } from './Button';
import { inputWrapperClass } from './inputs/inputStyles';
import { TextInput } from './inputs/TextInput';

export function EmailForm({
  customSearchParams,
  isLogin,
}: {
  isLogin?: boolean;
  customSearchParams?: string;
}) {
  const searchParams = useSearchParams();
  const { trackEvent } = useSegmentAnalytics();
  const [emailSent, setEmailSent] = useState(false);
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: { email: '' },
  });

  const submitCopy = isLogin ? 'Send sign in link' : 'Send signup link';
  const successCopy = isLogin ? 'Sign in link sent ✓' : 'Signup link sent ✓';

  const onSubmit: SubmitHandler<FieldValues> = data => {
    const email = data.email.trim();
    const redirectUrl = isLogin ? '/home' : '/registration/contact';

    const queryParams = getPreservedQueryString(searchParams);
    signInWithEmailLink(
      email,
      redirectUrl,
      customSearchParams ? customSearchParams : queryParams,
    );
    trackEvent('email_link_sent', { email });
    setEmailSent(true);
  };

  return (
    <form
      className='flex flex-col w-full gap-4'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={inputWrapperClass}>
        <TextInput
          control={control}
          id='email'
          label='Email'
          name='email'
          readOnly={emailSent}
          type='email'
        />
      </div>
      {emailSent ? (
        <div className='w-full flex justify-center items-center gap-3 h-auto py-3 text-[18px] font-light text-white bg-[#29004C] rounded-full select-none'>
          <p className='text-center'>{successCopy}</p>
        </div>
      ) : (
        <Button type='submit'>{submitCopy}</Button>
      )}
    </form>
  );
}

const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

const schema = yup.object({
  email: yup
    .string()
    .transform(value => value?.toLowerCase().trim())
    .matches(EMAIL_REGEX, 'Please correct your email format.')
    .required('Please enter your email.'),
});
