import { useState } from 'react';
import { useController } from 'react-hook-form';

import { InputProps } from '@/types/form';
import { capitalizeWord } from '@/utils/capitalizeWord';

import {
  defaultInputClass,
  errorInputClass,
  errorMessageClass,
  inputWrapperClass,
  labelClass,
} from './inputStyles';

export function TextInput({
  autoCapitalize,
  autoComplete,
  className,
  control,
  embellishment,
  hideErrorMessage,
  id,
  label,
  name,
  readOnly,
  type,
}: InputProps<string>) {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ name: name, control: control });
  const { name: fieldName, onBlur, onChange, ref, value } = field;

  const capitalize = (string: string) => {
    if (autoCapitalize === 'words') return capitalizeWord(string);
    if (autoCapitalize === 'characters') return string.toUpperCase();
    return string;
  };

  const extendedOnChange = (e: { target: { value: string } }) =>
    onChange(capitalize(e.target.value));

  return (
    <div className={inputWrapperClass}>
      <input
        autoComplete={autoComplete}
        className={`${invalid ? errorInputClass : defaultInputClass} ${className}`}
        disabled={readOnly}
        id={id}
        name={fieldName}
        placeholder='   '
        ref={ref}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={extendedOnChange}
      />
      {embellishment && (
        <div className='absolute top-5 right-3 lg:right-4'>{embellishment}</div>
      )}
      <label className={labelClass} htmlFor={id}>
        {label}
      </label>
      {!hideErrorMessage && invalid && (
        <p className={errorMessageClass}>{error?.message}</p>
      )}
    </div>
  );
}

export const PasswordInput = (props: InputProps<string>) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='flex flex-col items-start w-full gap-2'>
      <TextInput
        {...props}
        embellishment={
          <button
            className='text-[14px] pl-2 text-[#65636D]'
            type='button'
            onClick={() => setShowPassword(!showPassword)}
          >
            <svg
              className='shrink-0 size-3.5'
              fill='none'
              height='24'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox='0 0 24 24'
              width='24'
            >
              <path
                className={showPassword ? 'block' : 'hidden'}
                d='M9.88 9.88a3 3 0 1 0 4.24 4.24'
              ></path>
              <path
                className={showPassword ? 'block' : 'hidden'}
                d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'
              ></path>
              <path
                className={showPassword ? 'block' : 'hidden'}
                d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'
              ></path>
              <line
                className={showPassword ? 'block' : 'hidden'}
                x1='2'
                x2='22'
                y1='2'
                y2='22'
              ></line>
              <path
                className={showPassword ? 'hidden' : 'block'}
                d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'
              ></path>
              <circle
                className={showPassword ? 'hidden' : 'block'}
                cx='12'
                cy='12'
                r='3'
              ></circle>
            </svg>
          </button>
        }
        id='password'
        type={showPassword ? 'text' : 'password'}
      />
    </div>
  );
};
