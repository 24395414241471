'use client';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import { Button } from '@/app/components/Button';
import { EmailForm } from '@/app/components/EmailForm';
import { GoogleLogoSvg } from '@/app/components/icons/GoogleLogoSvg';
import { Loader } from '@/app/components/Loader';
import { TermsAndConditions } from '@/app/components/TermsAndConditions';
import { signInWithGoogle } from '@/firebase/auth';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import useMemberData from '@/hooks/useMemberData';
import getFromLocalStorage from '@/utils/getFromLocalStorage';
import getPreservedQueryString from '@/utils/getPreservedQueryString';

export default function LoginPage() {
  const searchParams = useSearchParams();
  const { isLoading } = useMemberData();
  const router = useCustomRouter();

  const onSignInWithGoogle = () => signInWithGoogle(onLoginSuccess);

  const onLoginSuccess = () => {
    const redirectPath = getFromLocalStorage('redirectPath');
    if (redirectPath) {
      localStorage.removeItem('redirectPath');
      router.push(redirectPath);
    } else {
      router.push('/home');
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className='flex flex-col items-center justify-between flex-1 w-full px-8 pt-16 bg-palestLavender'>
      <div className='flex flex-col items-center justify-start w-full lg:w-1/3'>
        <Button variant='light' onClick={onSignInWithGoogle}>
          <GoogleLogoSvg />
          Sign in with Google
        </Button>
        <div className='flex items-center justify-center my-8 text-gray-500'>
          <span className='block w-24 m-2 border-b border-solid lg:w-40 border-b-gray-400' />
          <p>or</p>
          <span className='block w-24 m-2 border-b border-solid lg:w-40 border-b-gray-400' />
        </div>
        <EmailForm isLogin />
        <Link
          className='pt-8 text-[#45007F]'
          href={'/registration' + getPreservedQueryString(searchParams)}
        >
          Don't have an account? <span className='underline'>Sign up</span>
        </Link>
      </div>
      <TermsAndConditions />
    </div>
  );
}
